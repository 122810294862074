<template>
    <div class="billSaleReport defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <el-dropdown class="bnt verticalCenter" split-button @click="queryType=true" :class="{selected:queryType==true}"  v-if="ReportDataFromDays>=0">
                    {{({0:"按日期范围",1:"按点单时间",2:"按点单区间"})[showType]}}
                    <template #dropdown>
                        <el-dropdown-menu @click="queryType=true">
                            <el-dropdown-item @click="showType=0">按日期范围</el-dropdown-item>
                            <el-dropdown-item @click="showType=1">按点单时间</el-dropdown-item>
                            <el-dropdown-item @click="showType=2">按点单区间</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"  v-if="showType==0"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"> </el-date-picker>
                    <el-date-picker class="from-date timerange" v-else
                        v-model="businessHours"
                        type="datetimerange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                        format="YYYY-MM-DD HH:mm"
                    > </el-date-picker>
                </div>
                <div class="inline-block orderChannel">
                    <div class="lable-txt">菜品类别：</div>
                    <div class="from-input" @click="departClick(2)">
                        <input type="text" placeholder="请选择" v-model="foodName" readonly />
                        <i class="iconfont icon-yewuliucheng"></i>
                    </div>
                </div>
                <div class="inline-block orderChannel">
                    <div class="lable-txt">出品部门：</div>
                    <div class="from-input" @click="departClick(1)">
                        <input type="text" placeholder="请选择" v-model="deparName" readonly />
                        <i class="iconfont icon-yewuliucheng"></i>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">餐厅:</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="roomids" multiple collapse-tags @removeTag="()=>roomids=[]">
                                <el-option :value="item.Room_AutoID" :label="item.Room_Code+'-'+ item.Room_Name" v-for="item in PosRooms" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" v-if="queryType==false">
                    <div class="flex-box">
                        <div class="lable-txt">状态查询：</div>
                        <div class="from-input elselect" >
                            <el-select v-model="foodStatusValue" placeholder="请选择">
                                <el-option
                                v-for="item in foodStatusLikst"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="from-input search-input"><input type="text" v-model="foodDimSelect"  placeholder="菜品模糊检索" /></div>
                        <div class="search-flex">
                            <div class="bnt-search" @click="GetBillCheck()" >查询</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table" ref="tableBox"  v-table-el-height="'tableEl'" >
                <el-table ref="tableEl" id="salatableEl" :data="tablePageList" style="width: 100%;" border show-summary 
                :summary-method="getSummaries" 
                @current-change="handleCurrentChange"
                :row-class-name="tableRowClass"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column fixed="left" prop="Eat_XFCode" label="菜品编码" min-width="70" :formatter="priceFormat">
                        <template #default="scope">
                            <span class="leftssize" >{{scope.row.Eat_XFCode}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" label="菜品名称" min-width="110" :formatter="priceFormat">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" label="单位" min-width="50" :formatter="priceFormat">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_XFSize}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" prop="Eat_SetPrice" label="当前定价" min-width="70" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column label="销售数量" min-width="240">
                        <el-table-column prop="SaleNumber" label="单点销量" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SaleNumber!=0">{{scope.row.SaleNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="GiftNumber" label="赠送销量" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.GiftNumber!=0">{{scope.row.GiftNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TCSaleNumber" label="套餐销量" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TCSaleNumber!=0">{{scope.row.TCSaleNumber}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="SaleNumberTotal" label="总销量" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SaleNumberTotal!=0">{{scope.row.SaleNumberTotal}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="销售单价" min-width="240">
                        <el-table-column prop="SaleAvePrice" label="单点均价" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SaleAvePrice!=0">{{scope.row.SaleAvePrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TCAvePrice" label="套餐均价" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TCAvePrice!=0">{{scope.row.TCAvePrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="AvePrice" label="销售均价" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.AvePrice!=0">{{scope.row.AvePrice}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="SetAvePrice" label="原价均价" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SetAvePrice!=0">{{scope.row.SetAvePrice}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="消费金额数据" min-width="460">
                        <el-table-column prop="SaleMoney" label="单点销售金额" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SaleMoney!=0">{{scope.row.SaleMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TCSaleMoney" label="套餐销售金额" min-width="80" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TCSaleMoney!=0">{{scope.row.TCSaleMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TotalSaleMoney" label="销售总额" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TotalSaleMoney!=0">{{scope.row.TotalSaleMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TotalZKMoney" label="优惠总额" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TotalZKMoney!=0">{{scope.row.TotalZKMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TotalCheckMoney" label="折后金额" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TotalCheckMoney!=0">{{scope.row.TotalCheckMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TotalFactMoney" label="实收金额" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TotalFactMoney!=0">{{scope.row.TotalFactMoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TotalSetMoney" label="原价总额" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TotalSetMoney!=0">{{scope.row.TotalSetMoney}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="占比px%" min-width="120">
                        <el-table-column prop="SaleNumberTotal" label="总销量" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.SaleNumberTotal!=0">{{scope.row.SaleNumberTotal}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="TotalRatio" label="累积占比" min-width="60" data-format="number">
                            <template #default="scope">
                                <span class="rigths" v-if="scope.row.TotalRatio!=0">{{scope.row.TotalRatio}}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="Eat_AssNun"  label="辅助数量" min-width="80" data-format="number">
                        <template #default="scope">
                            <span class="rigths" v-if="scope.row.Eat_AssNun!=0">{{scope.row.Eat_AssNun}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_AssSize" label="辅助单位" min-width="80">
                        <template #default="scope">
                            <span class="lefts">{{scope.row.Eat_AssSize}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block">
                <el-pagination class="fy"
                     layout="prev, pager, next"
                     @current-change="current_change"
                     :hide-on-single-page="istag"
                     :total="total"
                     :page-size="pagesize"
                     background
                    >
                </el-pagination>
            </div>
        </div>
        <!--选择服务员或销售员-->
        <modal-load :isShow="deparShow">
            <department-Model :isShow="deparShow" v-on:closeModel="deparShow=false" :deparType="deparType" :foodMainCategory="foodMainCategory" :CHHDPIDList="CHHDPIDList" @deparConfirm="deparConfirm" ></department-Model>
        </modal-load>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
     </div>
</template>
<script>
import departmentModel from './model/departmentModel.vue'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'
/**菜品销售统计表 */
export default {
    components:{departmentModel,checkUserButtonModel},
    name:'billSaleReport',
    data(){
        return {
            //0按日期范围 1按点单时间  2按点单区间
            showType:0,
            pagesize:9,//每页的数据条数
            currentPage:1,//默认开始页面
            istag: true,
            userInfo:'',
            /**表格数据 */ 
            tableList:[],
            deparShow:false,
            deparType:0,
            deparList:[],
            deparName:'',
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            /**出品部门集合 */
            CHHDPIDList:[],
            foodName:'',
            foodList:[],
            checkShow:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            checkBtnlist:{},
            /**菜品数据 */
            posFoodData:{},
            /**状态 */
            foodStatusLikst:[{name:'所有',id:0},{name:'已结',id:1},{name:'未结',id:2}],
            foodStatusValue:0,
            /**菜品模糊检索 */
            foodDimSelect:'',
            /**餐厅数据 */
            PosRooms:[],
            //餐厅id
            roomids:[],
        }
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //大类数据
        foodMainCategory(){
            let foodMainCategory=[];
            this.posFoodData?.data?.map(item=>{
                let depart={
                    id:item.MainCategory_ID,
                    Name:item.MainCategory_Name,
                    children:[],
                    Hierarchy:1
                }
                if(item.FoodSubCategorys){
                    item.FoodSubCategorys.map(eat=>{
                        let chieat={
                            id:eat.SubCategory_ID,
                            Name:eat.SubCategory_Name,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                foodMainCategory.push(depart)
            })
            return foodMainCategory;
        },
        /**过滤数据 */
        tableFilter(){
            let list=this.tableList||[];
            if(this.foodDimSelect!=''){
                let MenuTxt=this.foodDimSelect.toLowerCase();
                list=list.filter(item=>(item.Eat_XFName || "").toLowerCase().indexOf(MenuTxt)>=0 
                    || (item.Eat_XFCode || "").toLowerCase().indexOf(MenuTxt)>=0 
                    || (item.PINYIN || "").toLowerCase().indexOf(MenuTxt)>=0);
            }
            return list;
        },
        /**分页数据 */
        tablePageList(){
            if(this.tableFilter && this.tableFilter.length>0){
                return this.tableFilter.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize);
            }
            return [];
        },
        //数据总数
        total(){
            return this.tableFilter?.length;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.tempUserId='',this.tempOperatorName='';
        this.nowadays();
       
        this.deparType=0
        /**出品部门 */
        this.$cacheData.ChhDepart().then((d)=>{
            this.CHHDPIDList = []
            d.map(item=>{
                let depart={
                    id:item.CHH_DEPARTKINDID,
                    Name:item.CHH_DEPARTKINDNAME,
                    children:[],
                    Hierarchy:1
                }
                if(item.EatChhDeparts){
                    item.EatChhDeparts.map(eat=>{
                        let chieat={
                            id:eat.CHH_DEPARTID,
                            Name:eat.CHH_DEPARTNAME,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                this.CHHDPIDList.push(depart)
            })
        }).catch(()=>{
              this.$alert('暂无出品部门', "提示", {confirmButtonText: "确定"});
        })
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        
        this.$cacheData.EatRooms().then((d)=>{
            this.PosRooms=d;
        }).catch((e)=>{
            this.$alert("桌台数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
        });
    },
    watch:{
        /**菜品搜索 */
        foodDimSelect(newVal,oldVal){
            if(newVal && (newVal.indexOf('+')>=0 || newVal.indexOf('-')>=0 )){
                this.foodDimSelect=oldVal;
                return;
            }
            this.setTablePageSize()
            this.currentPage=1;
        }
    },
    methods:{
         disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                let height=this.$refs.tableBox.clientHeight - 80;
                this.pagesize=parseInt(height/50);
            }
        },
        /**分页 */
        tableRowClassName({rowIndex}){
            if (rowIndex === 0) {
            return 'th';
            }
            return '';
        },
        switchChange(){
          this.istag = !this.istag;
        },
        current_change(currentPage){
            this.currentPage = currentPage;
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"菜品销售统计表",
                list:this.tableFilter
            })
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**出品部门 */
        departClick(type){
            this.deparType=type
            if(this.deparShow==false){
                this.deparShow =true
            }else{
                this.deparShow =false
            }
        },
        /**筛选条件返回 */
        deparConfirm(deparList){
            if(this.deparType==1){
                this.deparList=[],this.deparName=''
                deparList.map(depa=>{
                    if(depa.Hierarchy==2){
                        this.deparName += depa.Name+','
                        this.deparList.push(depa.id)
                    }
                })
            }
            if(this.deparType==2){
                this.foodList=[],this.foodName=''
                deparList.map(depa=>{
                    if(depa.Hierarchy==2){
                        this.foodName += depa.Name+','
                        this.foodList.push(depa.id)
                    }
                })
            }
            this.deparShow =false
        },
        /**查询 */
        GetBillCheck(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let Begin_Date="",End_Date="";
            if(this.showType!=0){
                Begin_Date=new Date(time[0]).Format("yyyy-MM-dd hh:mm:00");
                End_Date=new Date(time[1]).Format("yyyy-MM-dd hh:mm:59");
            }else{
                Begin_Date=new Date(time[0]).Format("yyyy-MM-dd");
                End_Date=new Date(time[1]).Format("yyyy-MM-dd");
            }

            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateType:this.showType,//日期格式  0:按日期; 1:按点单时间; 2:按点单区间
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:Begin_Date,//开始日期
                End_Date:End_Date, //结束日期
                XFKindIDList:this.foodList, //分类ID
                CHHDPIDList:this.deparList, //出品部门ID
                RoomIds:this.roomids,//餐厅id
                Is_CheckedOut:0,
                IsHideMarkedBills:this.$global.isLableData //是否隐藏带标签的订单
            }
            if(this.queryType==false){
                param.Is_CheckedOut = this.foodStatusValue
            }
            console.log(param)
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            this.$httpAES.post("Bestech.CloudPos.SaleReport",param,false).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else if(d.ResponseHeader.ResultCode==3003){
                    /**权限不足,去验证保存权限 */
                    this.checkBtnlist = Object.assign(this.checkBtnlist,{ProjectItem_Key:d.ResponseHeader.Msg_Reserve})
                    this.checkShow = true;
                }else{
                    
                    this.tableList=[]
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        /**权限验证返回 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.tempUserId = date.User_ID
            this.tempOperatorName = date.Login_Name
            this.GetBillCheck()
        },
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
            if (index === 0) { // 只找第一列放合计
                sums[index] = '合计：'
                return
            }else if (index!==1&&index!==2&&index!==3&&index!==20&&index!==8&&index!==9&&index!==10&&index!==11&&index!==22) {
                const values = this.tableFilter?.map(item => Number(item[column.property]))
                if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr)
                    if (!isNaN(value)) {
                        return  Number(Number(prev)+Number(curr)).toFixed(2)
                    } else {
                        return  Number(prev).toFixed(2)
                    }
                },0)} else {
                    sums[index] = 'N/A'
                    }
                } else {
                    sums[index] = '--'
                    }
                })
            return sums
        },
        //奇偶行背景色不同
        setCurrent(row) {
            this.$refs.tableEl.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        tableRowClass({row, rowIndex}) {
            if ((rowIndex + 1) % 2 === 0) {
                return 'success-row';
            }
            return '';
        }
    }
}
</script>

<style lang="scss" >
@import './billSaleReport.scss'
</style>